import React from "react";
import Heading from "utils/Heading";
import TBBLink from "utils/TBBLink";
import Layout from "components/Layout"
import { PageContext } from "utils/context";

const Simple404 = ({ location, pageContext }) => {
  return (
    <PageContext.Provider
        value={{
          ...pageContext,
          location: location,
        }}
      >
      <Layout location={location}>
        <div className={`relative py-8 overflow-x-hidden lg:py-32 max-w-xl mx-auto`}>
            <div className="relative mx-auto px-6 flex flex-col justify-center items-start h-full">
                <div className="flex items-center mb-8">
                  <div className="border-r border-primary">
                  <Heading size="h1" className={`text-white max-w-4xl transform ease transition-all duration-1000 mr-3 !mb-0`}>
                      404
                  </Heading>
                </div>
                <Heading size="h4" className="ml-3 !mb-0 !leading-8">
                    page not found
                </Heading>
                </div>
                <div className="max-w-lg text-white text-base lg:text-lg mb-6">
                    Sorry the page you have requested was not found.
                </div>
                <TBBLink className="max-w-md flex items-center text-lg text-primary group" to='/'>
                  Back to homepage
                  <div class="scale-75 ml-3 md:h-9 h-7 md:w-9 w-7 bg-[#F9DFE8] rounded-full relative  flex justify-center items-center duration-300 group-hover:rotate-180">
                      <div class="bg-[#091DF3] md:h-[20px] h-[16px] md:w-1 w-[3px] absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"></div>
                      <div class="bg-[#091DF3] md:h-[20px] h-[17px] md:w-1 w-[3px] absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 rotate-90"></div>
                  </div>
                </TBBLink>      
            </div>         
        </div>
      </Layout>
    </PageContext.Provider>
  );
};

export default Simple404;
